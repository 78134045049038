<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { SCButton, ButtonVariant } from '@nsftx/seven-components'
import router from '@/router'
import { useLobbyStore } from '@/stores/lobby/lobbyStore'

const { lobbyItemContent } = storeToRefs(useLobbyStore())
const title = computed(() => lobbyItemContent.value.title)

const navigateToHome = () => {
  router.push({ name: 'home' })
}
</script>

<template>
  <div class="st-lobby-navigation">
    <div class="st-lobby-navigation__title sc-text-button sc-px-5 sc-ml-2">
      {{ title }}
    </div>
    <SCButton :variant="ButtonVariant.Primary" @click="navigateToHome">
      {{ $t('lobby_back_to_betting_area') }}
    </SCButton>
  </div>
</template>

<style scoped lang="scss">
.st-lobby-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__title {
    color: $color-white;
  }
}
</style>
