<script setup lang="ts">
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { SCModal, SCInput, SCButton, ButtonVariant } from '@nsftx/seven-components'
import i18n from '@/plugins/translationsPlugin'
import { useTicketCheckStore } from '@/stores/ticket-check/ticketCheckStore'
import ticketCheckService from '@/modules/ticket-check/ticketCheckService'

const { t } = i18n.global
const isTicketIdChanged = ref(false)
const isFormInvalid = computed(() => !isTicketIdChanged.value || !!ticketIdError.value)
const ticketCheckStore = useTicketCheckStore()
const { ticketId, isTicketCheckFormOpened } = storeToRefs(ticketCheckStore)
const { closeTicketCheckModal } = ticketCheckStore

const ticketIdError = computed(() => {
  if (!isTicketIdChanged.value) return ''

  if (!ticketId.value) return t('validation_required_field')

  return ''
})

const cleanUpForm = () => {
  isTicketIdChanged.value = false
}

const onTicketCheck = () => {
  cleanUpForm()
  closeTicketCheckModal()
  ticketCheckService.checkTicket()
}
</script>

<template>
  <SCModal v-model="isTicketCheckFormOpened" with-close-icon @update:model-value="cleanUpForm">
    <template #title>
      <div class="st-ticket-check--title" data-cy="ticketModalTitle">{{ $t('ticket_check') }}</div>
    </template>

    <template #content>
      <div class="st-w-100">
        <div class="sc-text-caption-1 sc-text-primary-2 sc-mb-6" data-cy="description">
          {{ $t('ticket_check_form_content_description') }}
        </div>

        <SCInput
          v-model="ticketId"
          class="sc-pb-5"
          autocomplete="off"
          :placeholder="$t('ticket_id')"
          :error="!!ticketIdError"
          :hint="ticketIdError"
          block
          @input="isTicketIdChanged = true"
        />
      </div>
    </template>

    <template #actions>
      <SCButton
        :variant="ButtonVariant.Primary"
        :disabled="isFormInvalid"
        width="100%"
        data-testid="checkButton"
        @click="onTicketCheck"
      >
        {{ $t('common_check') }}
      </SCButton>
    </template>
  </SCModal>
</template>

<style lang="scss">
.st-ticket-check {
  &--title {
    text-transform: capitalize;
  }
}
</style>
