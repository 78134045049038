<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { SCModal, SCButton, ButtonVariant } from '@nsftx/seven-components'
import { useTicketCheckStore } from '@/stores/ticket-check/ticketCheckStore'

const { isTicketCheckDetailsOpened, ticketId } = storeToRefs(useTicketCheckStore())
</script>

<template>
  <SCModal v-model="isTicketCheckDetailsOpened" width="1428px" with-close-icon>
    <template #title>System | Open</template>

    <template #content> Ticket ID: {{ ticketId }}</template>

    <template #actions>
      <SCButton :variant="ButtonVariant.Primary" data-cy="payoutButton">
        {{ $t('common_payout') }}
      </SCButton>
    </template>
  </SCModal>
</template>
